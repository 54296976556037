@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@keyframes rotate-once {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-once {
  animation: rotate-once 1s linear;
}

html {
  scroll-behavior: smooth;
}

/* STATUSES */
.status-badge {
  display: inline-block;
  border-radius: 15px;
  padding: 4px 10px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
  vertical-align: middle;


  &.block {
    font-weight: normal;
    display: block;
  }
}

.inactive-status {
  color: black;
  background: #f6f6f6;
}

.orange-status {
  color: #ff6a00;
  background: #ffe6d3;
}

.green-status {
  color: #06b73d;
  background: rgb(231, 255, 209);
}

.blue-status {
  color: #0076fa;
  background: rgb(214, 236, 255);
}

.red-status {
  color: #cf241f;
  background: #fce8e6;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.animate-fade-in-bounce {
  animation: fadeIn 0.8s ease-out, bounce 1s ease-out 1s;
}

/* FOR NOTIFICATION PANEL */
.status-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  font-weight: 600;
}

/* MODAL */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 0.3s ease-out;
}
